export default {
  viaticos: {
    constantes: {
      ESTATUS_ACEPTADO_SIN_REVISION: 1
      ,ESTATUS_ACEPTADO: 2
      ,ESTATUS_PROCESO_PAGO: 3
      ,ESTATUS_PAGO_TRANSICION: 4
      ,ESTATUS_PAGADA: 5
      ,ESTATUS_CONCILIACION: 6
      ,ESTATUS_VALIDANDO_COMPROBANTES: 7
      ,ESTATUS_PAGO_MONTO_EXTRA: 8
      ,ESTATUS_COBRO_MONTO_SOBRANTE: 9
      ,ESTATUS_VALIDANDO_COBRO_MONTO_SOBRANTE: 10
      ,ESTATUS_CERRADO: 11
      ,ESTATUS_RECHAZADO: 1000
      ,ESTATUS_ERROR_PAGO: 1001
      ,ESTATUS_CONCILIACION_ICONSISTENCIA: 1002
      ,ESTATUS_VERIFICACION_VENCIDO: 1003
      ,ESTATUS_ERROR_PAGO_MONTOS: 1004
      ,ESTATUS_RECHAZO_COBRO_MONTO_SOBRANTE: 1005
      ,TIPO_DEPOSITO: 1
      ,TIPO_DESCUENTO: 2
    },
    estatus_validos: function(valor, estatus) {
      let estatus_valores = [];

      estatus.forEach(llave => {
        if (this.constantes[llave])
          estatus_valores.push(this.constantes[llave]);
      })

      return estatus_valores.indexOf(valor) != -1;
    }
    ,evidencias: {
      ESTATUS_ACTIVO: 1
      ,ESTATUS_APROBADO: 2
      ,ESTATUS_RECHAZADO: 100
      ,TIPO_DEPOSITO: 1
      ,TIPO_DESCUENTO: 2
    }
  },
}