<template>
  <div>
    <Modal :options="{width: '40vw', type: 'secondary'}">
      <div class="title">Agregar evidencia</div>
      <div class="body">
        <div class="row form-group">
          <label for="monto" class="col-form-label col-sm-3">Monto</label>
          <div class="col-sm-9">
            <IC v-model="evidencia.monto" id="monto" name="monto" label="$" :formats="['moneyFormat']" />
          </div>
        </div>
        <div class="row form-group">
          <label for="fecha_pago" class="col-form-label col-sm-3">Fecha de pago</label>
          <div class="col-sm-9"><input v-model="evidencia.fecha_deposito" type="datetime-local" name="fecha_pago" id="fecha_pago" class="form-control"></div>
        </div>
        <div class="row form-group">
          <label for="" class="col-form-label col-sm-3">Documento</label>
          <div class="col-sm-9">
            <div class="input-group mb-3">
              <div class="form-control">{{ nombre_archivo }}</div>
              <div class="input-group-append">
                <button class="btn btn-secondary" @click="$refs.seleccionar_archivo.click()">Agregar documento</button>
              </div>
            </div>
            <input class="seleccionar_archivo" type="file" name="seleccionar_archivo" id="seleccionar_archivo" ref="seleccionar_archivo" @change="agregar_archivo">
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-primary mr-2" @click="agragar_evidencia">Agregar</button>
            <button class="btn btn-danger" @click="$emit('close')">Cancelar</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import IC from '@/components/Form/InputCompuesto'

import api from '@/apps/constelacion/api/tesoreria';
import config from '@/apps/tesoreria/configuracion/viaticos'

export default {
  components: {
    Modal, IC
  },
  props: {
    viatico: {
      type: Object,
      required: true,
      default() {
        return {

        }
      }
    }
  },
  data() {
    return {
      evidencia: {
        monto: null,
        fecha_deposito: null
      }
      ,archivos: []
      ,nombre_archivo: null
    }
  }
  ,methods: {
    agregar_archivo() {
      if (this.$refs.seleccionar_archivo.files.length == 0)
        return this.$helper.showMessage('Error','Debes seleccionar un documento a agregar','error','alert');

      this.archivos.push(this.$refs.seleccionar_archivo.files[0]);
      this.nombre_archivo = this.$refs.seleccionar_archivo.files[0].name;
      this.$refs.seleccionar_archivo.value = null;
    },
    async agragar_evidencia() {
      try {
        let payload = new FormData();
        payload.append('monto', this.evidencia.monto);
        payload.append('fecha_deposito', this.evidencia.fecha_deposito);
        payload.append('documentos[]', this.archivos[0]);

        await api.agregar_evidencia(this.viatico.id, payload);
        this.$emit('update');
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.seleccionar_archivo {
  display: none;
}
</style>