import Vue from 'vue'
import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_TESORERIA);

export default {
  obtener_viaticos(options) {
    return http.get(utils.url_options(`/viaticos/micuenta`, options));
  },

  obtener_viatico(id) {
    return http.get(`/viaticos/micuenta/${id}`);
  },

  crear_viatico(payload) {
    return http.post(`/viaticos/micuenta`, payload);
  },

  editar_viatico(id, payload) {
    return http.put(`/viaticos/micuenta/${id}`, payload);
  },

  agregar_comentario(id, payload) {
    return http.post(`/viaticos/micuenta/${id}/comentarios`, payload);
  },

  subir_documento(id, payload) {
    return http.post(`/viaticos/micuenta/${id}/documentos`, payload);
  },

  descargar_documento(viatico, id) {
    return http.get(`/viaticos/micuenta/${viatico}/documentos/${id}`, {responseType: 'blob'})
  },

  visualizar_documento(viatico, id) {
    return http.get(`/viaticos/micuenta/${viatico}/documentos/${id}`, {responseType: 'arraybuffer'})
  },

  eliminar_documento(viatico, id) {
    return http.delete(`/viaticos/micuenta/${viatico}/documentos/${id}`);
  },

  buscar_catalogo(catalogo) {
    return http.get(`/viaticos/micuenta/catalogo/${catalogo}`);
  },

  autorizadores() {
    return http.get(`/viaticos/micuenta/autorizadores`);
  },

  leer_factura(payload) {
    return http.post(`/viaticos/micuenta/factura`, payload);
  },

  obtener_formas_pagos() {
    return http.get(`/viaticos/micuenta/formas_pago`);
  },

  crear_forma_pago(payload) {
    return http.post(`/viaticos/micuenta/formas_pago`, payload);
  },
  
  crear_comprobante(id, payload) {
    return http.post(`/viaticos/micuenta/${id}/comprobante`, payload);
  },

  eliminar_comprobante(id, comprobante) {
    return http.delete(`/viaticos/micuenta/${id}/comprobante/${comprobante}`);
  },

  agregar_comentario_concepto(id, comprobante, payload) {
    return http.post(`/viaticos/micuenta/${id}/comprobantes/${comprobante}/comentarios`, payload);
  },

  agregar_evidencia(id, payload) {
    return http.post(`/viaticos/micuenta/${id}/evidencias`, payload);
  },

  eliminar_evidencia(id, evidencia) {
    return http.delete(`/viaticos/micuenta/${id}/evidencias/${evidencia}`);
  },

  descargar_documento_evidencia(id, evidencia, documento) {
    return http.get(`/viaticos/micuenta/${id}/evidencias/${evidencia}/documentos/${documento}`, {responseType: 'arraybuffer'});
  }
}