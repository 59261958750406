<template>
  <div>
    <Modal :options="{width: '50vw', close:true}" @close="$emit('close')">
      <div class="title">Evidencia ${{ $helper.moneyFormat(evidencia.monto) }}</div>
      <div class="body">
        <div class="row">
          <div class="col-sm-8">
            <div class="row form-group">
              <label for="" class="col-form-label col-sm-3">Monto</label>
              <div class="col-sm-9">
                <div class="form-control">${{ $helper.moneyFormat(evidencia.monto) }}</div>
              </div>
            </div>
            <div class="row form-group">
              <label for="" class="col-form-label col-sm-3">Fecha deposito</label>
              <div class="col-sm-9">
                <div class="form-control">{{ evidencia.fecha_deposito }}</div>
              </div>
            </div>
            <div class="row form-group">
              <label for="" class="col-form-label col-sm-3">Tipo de pago</label>
              <div class="col-sm-9">
                <div class="form-control">{{ evidencia.tipo == 1 ? 'Depósito' : 'Descuento de nómina' }}</div>
              </div>
            </div>
            <div class="row form-group">
              <label for="" class="col-form-label col-sm-3">Estatus</label>
              <div class="col-sm-9">
                <div class="form-control">{{ estatus(evidencia.estatus) }}</div>
              </div>
            </div>
            <div v-if="evidencia.razon_rechazo" class="row form-group">
              <label for="" class="col-form-label col-sm-3">Razón rechazo</label>
              <div class="col-sm-9">
                <div class="form-control">{{ evidencia.razon_rechazo }}</div>
              </div>
            </div>
          </div>
          <div class="col-sm-4">
            <div class="row">
              <div v-if="['jpg','jpeg','bmp','png'].indexOf(evidencia.documentos[0].extension) != -1" class="col-sm-12">
                <img class="show-document mb-2" :src="archivo" alt="Documento"><br/>
                <button class="btn btn-secondary" @click="descargar_archivo">Descargar imágen</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12 text-right"><button class="btn btn-primary" @click="$emit('close')">Cerrar</button></div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';

import api from '@/apps/constelacion/api/tesoreria';

export default {
  components: {
    Modal,
  },
  props: {
    viatico: {
      type: Object,
      required: true
    }
    ,evidencia: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      binario: null,
      archivo: null
    }
  },
  mounted() {
    this.descargar_mostrar_archivo();
  },
  methods: {
    estatus(estatus) {
      switch(estatus) {
        case 1:
          return 'Activo';
        case 2:
          return 'Aprobado';
        case 100:
          return 'Rechazado';
      }
    }
    ,async descargar_mostrar_archivo() {
      try {
        this.binario = (await api.descargar_documento_evidencia(this.viatico.id, this.evidencia.id, this.evidencia.documentos[0].id)).data;
        let base64 = btoa(
          new Uint8Array(this.binario).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ''
          )
        );

        this.archivo = 'data:'+this.evidencia.documentos[0].mime+';base64,'+base64;
      }catch(e) {
        this.$log.info('error',e);
        this.$helper.showAxiosError(e,'Error');
      }
    }
    ,descargar_archivo() {
      this.$helper.descargar_documento(this.binario, this.evidencia.documentos[0].nombre_original);
    }
  }
}
</script>

<style lang="scss" scoped>
.show-document {
  width: 100%;
}
</style>